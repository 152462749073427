import './SubjectScoreSheet.css';
import '../styles/scoreCell.css';

import React from 'react';
import { inject, observer } from 'mobx-react';
import { getSnapshot, isValidReference } from 'mobx-state-tree';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { Checkbox, Dropdown, Empty, Icon, Menu, Spin } from 'antd';
import Card from '../common/card';
import NotMobile from '../common/display/NotMobile';
import { UpdateAssessmentScore as UpdateAssessmentScoreClass } from '../store/actions/UpdateAssessmentScore';

import scoresheetIcon from './assets/scoresheet_icon.svg';
import edit from '../common/assets/edit.svg';
import SelectButtonGroup from '../common/select_button_group/SelectButtonGroup';
import AttendanceReportLoading from '../common/loading/AttendanceReportLoading';
import StripeLoading from '../common/loading/StripeLoading';
import TextInput from '../common/form/TextInput';
import AddAssessment from './AddAssessment';
import { ModalContext } from '../common/modal';
import {CreateAssessmentBtn} from "./SubjectAssessment";

@inject('store')
@observer
class EditableCell extends React.Component {

    constructor(props) {
        super(props);

        const { updateAssessmentScore } = props;

        this.state = {
            editing: false,
            formModel: UpdateAssessmentScoreClass.create(getSnapshot(updateAssessmentScore)),
        };
    }

    toggleEdit = (e) => {
        e.stopPropagation();
        if (this.state.editing) {
            this.cancel();
        } else {
            this.edit();
        }
    };

    edit = () => {
        this.setState({
            editing: true,
        }, () => {
            this.domElm.getElementsByTagName( 'input' )[0].focus();
        });
    };

    save = (e) => {
        const { formModel: stateUpdateAssessmentScore } = this.state;
        const {student_id, assessment_id} = stateUpdateAssessmentScore;
        const { store: { subjectStore: { updateAssessmentScore: saveFunction } } } = this.props;

        const className = `dropDown-${student_id}-${assessment_id}`
        const focusMaintained = document.getElementsByClassName(className)[0].contains(e.relatedTarget) || this.domElm.contains(e.relatedTarget)
        if (focusMaintained) return

        this.setState(
            { editing: false },
            async () => {
                if (this.isValueChanged()) {
                    const { data, errors } = await saveFunction(stateUpdateAssessmentScore);
                    if (data) {
                        const a = UpdateAssessmentScoreClass.create(data.updateActionJSON);
                        this.setState({ formModel: a });
                    }else if(errors){
                        
                    }
                }
            },
        );
    };

    cancel = () => {
        this.setState({
            editing: false,
        });
    };

    isValueChanged = () => {
        const { updateAssessmentScore: { id: idp, ...otherP } } = this.props;
        const { formModel: { id: ids, ...otherS } } = this.state;

        return !isEqual(otherP, otherS);
    };

    handleKeyDown = (e) => {
        const { key } = e;
        switch (key) {
            case 'Enter':
            case 'Escape':
                this.save(e);
                break;
        }
    };

    render() {
        const { editing, formModel: updateAssessmentScore } = this.state;

        if (!isValidReference(() => updateAssessmentScore)) return null;

        const { student_id, assessment_id, collected, missing, comment, exempt, score, late, status } = updateAssessmentScore;

        const savingIcon = <Icon type="loading" style={{opacity: '0.7'}} spin />
        const failedIcon = <Icon type="exclamation-circle" style={{ color: 'rgb(251, 93, 93)', opacity: '0.7' }} />
        const commentIcon = <i style={{color: 'hsla(0,0%,74%,1)', fontSize: '14px', paddingLeft: '2px'}} className="material-icons">message</i>
        const iconTitle = status === 'saving' ? 'Saving' : 'Failed'

        let columnDetails = 
            (
                <>
                    {exempt && <span title='Student Exempted.'>EX</span>}
                    {!exempt && score}
                    {!exempt && (late || collected || missing) && (
                        <div className='badgeWrapper'>
                            {(collected) && (<div title='Collected' className='numberCircle collected'>C</div>)}
                            {(late) && (<div title='Late' className='numberCircle late'>L</div>)}
                            {(missing) && (<div title='Missing' className='numberCircle missing'>M</div>)}
                        </div>
                    )}
                    {comment && <sup title={comment}>{commentIcon}</sup>}
                </>
            )

        if (status !== 'editing') columnDetails = 
            (
                <Spin
                    title={iconTitle}
                    indicator={status === 'saving' ? savingIcon : failedIcon}
                >
                    {columnDetails}
                </Spin>
            )

        const menu = (
            <Menu onFocus={() => {this.setState({ editing: true })}} onClick={() => {this.setState({ editing: true })}}>
                {/* <Menu.Item>
                    <Checkbox checked={collected} onChange={(e) => updateAssessmentScore.toggleCollected(e.target.checked)}>Collected</Checkbox>
                </Menu.Item>
                <Menu.Item>
                    <Checkbox checked={missing} onChange={(e) => updateAssessmentScore.toggleMissing(e.target.checked)}>Missing</Checkbox>
                </Menu.Item>
                <Menu.Item>
                    <Checkbox checked={late} onChange={(e) => updateAssessmentScore.toggleLate(e.target.checked)}>Late</Checkbox>
                </Menu.Item> */}
                <Menu.Item>
                    <Checkbox checked={exempt} onChange={(e) => updateAssessmentScore.toggleExempt(e.target.checked)}>Exempt</Checkbox>
                </Menu.Item>{/*
                <Menu.Item>
                    <Label text="Comment">
                        <MultilineInput value={comment || ''} onChange={(e) => updateAssessmentScore.setComment(e.target.value)} />
                    </Label>
                </Menu.Item> */}
            </Menu>
        );

        return (
            <div
                className={`SubjectScoreSheet-cell touchable-highlight ${editing ? 'editing' : ''}`}
                title={comment}
                onClick={(e) => {if (!editing) this.toggleEdit(e)}}
                ref={(domNode) => {this.domElm = domNode}}
                onBlur={(e) => this.save(e)}
                onKeyDown={this.handleKeyDown}
            >
                {editing ? (
                    <Dropdown overlay={menu} visible={editing} trigger={[]} overlayClassName={`dropDown-${student_id}-${assessment_id}`}>
                        <TextInput
                            className='scoreField'
                            onChange={(e) => updateAssessmentScore.setScore(e.target.value)}
                            value={score || ''}
                        />
                    </Dropdown>
                ) : columnDetails}
            </div>
        );

    }
}

@inject('store','display')
@observer
class SubjectScoreSheet extends React.Component {
    termOptions = [
        { label: 'All', value: undefined },
        { label: 'First Term', value: 1 },
        { label: 'Second Term', value: 2 },
        { label: 'Third Term', value: 3 },
    ];

    componentDidMount() {
        const {
            store: {
                subjectStore: { loadScoresheetById },
                view: { selectedSubjectId },
            },
        } = this.props;
        loadScoresheetById(selectedSubjectId);
    }

    handleTermSelect = selectedValue => {
        const {
            store: {
                subjectStore: { loadScoresheetById },
                view: { selectedSubjectId, selectedSubject: { scoresheet } },
            },
        } = this.props;
        scoresheet.setAcademicTermFilter(selectedValue);
        loadScoresheetById(selectedSubjectId);
    };

    render() {
        const { display, store: { view: { selectedSubject }, subjectStore: { unsavedAssessmentScores } } } = this.props;
        
        if (!selectedSubject || !selectedSubject.scoresheet) return <AttendanceReportLoading />;
        const { scoresheet } = selectedSubject;
        const { attributes, filter_term, isLoading } = scoresheet;

        if (isEmpty(attributes)) return <AttendanceReportLoading />;

        const { assessments, assessment_scores, students } = scoresheet;

        const scoresheetStudents = students.sort((a, b) =>
            a.studentName.localeCompare(b.studentName, 'en', { sensitivity: 'base', ignorePunctuation: true }),
        );

        const groupedAssessmentScores = {};
        assessments.forEach(assessment => {
            if (!groupedAssessmentScores.hasOwnProperty(assessment.id)) groupedAssessmentScores[assessment.id] = {};
            students.forEach(student => {
                assessment_scores.forEach(assessment_score => {
                    const { student: { id: n_student_id }, assessment: { id: n_assessment_id } } = assessment_score;

                    if (student.id === n_student_id && assessment.id === n_assessment_id) {
                        groupedAssessmentScores[assessment.id][student.id] = assessment_score;
                    }
                });
            });
        });

        return (
            <Card
                style={{ marginBottom: 10, position: 'relative' }}
                title={(
                    <div className="flex-row">
                        <img src={scoresheetIcon} className="utils-prepend" alt="" /> Scoresheet
                    </div>
                )}
            >

                <StripeLoading loading={isLoading} />

                <div className="SubjectScoreSheetToolBar">
                    <NotMobile>
                        <p className='termSelectLabel'>Academic Term</p>
                    </NotMobile>
                    <SelectButtonGroup
                        selectOptions={this.termOptions}
                        onChange={this.handleTermSelect}
                        value={filter_term}
                    />
                    <CreateAssessmentBtn />
                </div>
                
                {assessments.length < 1 && (
                    <Empty
                        imageStyle={{ height: 60 }}
                        description={<span>No assessment has been created.</span>}
                    />
                )}

                {scoresheetStudents.length < 1 && (
                    <Empty
                        imageStyle={{ height: 60 }}
                        description={<span>No student {selectedSubject.isElective ? 'assigned to this elective subject' : 'in Batch'}</span>}
                    />
                )}

                {scoresheetStudents.length > 0 && assessments.length > 0 && (
                    <div className="card SubjectScoreSheet-main">
                        {
                            scoresheetStudents.length > 0 && assessments.length > 0 && (
                                <div className="SubjectScoreSheet-grid">
                                    <div className="SubjectScoreSheet-col SubjectScoreSheet-leftPane">
                                        <div className="NameColumnHeader">
                                            <div className='nameLabel'>Name</div>
                                            <NotMobile>
                                                <div>&nbsp;</div>
                                                <div>Category</div>
                                                <div>Points</div>
                                            </NotMobile>
                                        </div>
                                        {scoresheetStudents.map(
                                            ({ id, studentName, nameAbbreviation }) => (
                                                <div key={id} className="SubjectScoreSheet-cell">
                                                    {display.isMobile ? nameAbbreviation : studentName}
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    {assessments.map(assessment => (
                                        <div key={assessment.id} className="SubjectScoreSheet-col">
                                            <div className="AssessmentColumnHeader">
                                                <div className='first'>
                                                    <div title={assessment.name}>
                                                        <span>{assessment.abbreviation}</span>
                                                        {assessment.dueDate}
                                                    </div>
                                                    <ModalContext.Consumer>
                                                        {(context) => (
                                                            <button
                                                                type='button'
                                                                title='Edit Assessment'
                                                                onClick={() => context.setContent(
                                                                    'Edit Assessment',
                                                                    <AddAssessment onCancel={context.closeModal} editMode assessmentId={assessment.id} />,
                                                                    true
                                                                )}
                                                            >
                                                                <img src={edit} alt="edit" />
                                                            </button>
                                                        )}
                                                    </ModalContext.Consumer>
                                                </div>
                                                <div title={assessment.assessmentCategoryName}>{assessment.assessmentCategoryAbbreviation}</div>
                                                <div>{assessment.describePoints}</div>
                                            </div>

                                            {scoresheetStudents.map((student) => {
                                                const scoreModel = groupedAssessmentScores[assessment.id][student.id];

                                                let updateAssessmentScoreModel;
                                                unsavedAssessmentScores.forEach((uas) => {
                                                    const { student_id, assessment_id } = uas;

                                                    if (assessment_id === assessment.id && student.id === student_id) updateAssessmentScoreModel = uas;
                                                });

                                                let newFormModel;
                                                if (updateAssessmentScoreModel) {
                                                    newFormModel = updateAssessmentScoreModel;
                                                } else if (scoreModel) {
                                                    newFormModel = UpdateAssessmentScoreClass.create(scoreModel.updateActionJSON);
                                                } else {
                                                    newFormModel = UpdateAssessmentScoreClass.create({
                                                        student_id: student.id,
                                                        assessment_id: assessment.id,
                                                        exempt: true,
                                                    });
                                                }

                                                return (<EditableCell key={`${assessment.id}-${student.id}`} updateAssessmentScore={newFormModel} />);
                                            })}
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    </div>
                )}


            </Card>
        );
    }
}


export default SubjectScoreSheet;