/* eslint-disable no-underscore-dangle */
import { types, getParent, applySnapshot, flow } from "mobx-state-tree"
import {dateType, today} from "../types/dateType"
import { Gender } from "../models/Gender"
import { StudentGuardianRelation } from "./StudentGuardianRelation";

export const DropStudent = types
    .model("DropStudent", {
        date_dropped: dateType,
        reason_for_leaving: types.string,
    })

export const bloodGroupOptions = ["A+", "A-", "AB+", "AB-", "B+", "B-", "O+", "O-"]
export const religionOptions = ["christianity", "islam"]

export const CreateStudent = types
    .model("CreateStudent", {
        id: types.maybeNull(types.string),
        username: "",
        email: types.maybeNull(types.string),
        admission_date: types.optional(types.union(dateType, types.string), today),
        first_name: "",
        middle_name: types.maybeNull(types.string),
        last_name: "",
        student_category_ids: types.maybeNull(types.array(types.number)),
        gender: types.maybeNull(Gender),
        photo: types.maybeNull(types.string),
        blood_group: types.maybeNull(types.enumeration("blood_groups", bloodGroupOptions)),
        nationality: types.maybeNull(types.string),
        state_of_origin: types.maybeNull(types.string),
        lga_of_origin_id: types.maybeNull(types.union(types.string, types.number)),
        religion: types.maybeNull(types.enumeration("religions", religionOptions)),
        address: types.maybeNull(types.string),
        batch_id_cache: types.maybeNull(types.string),
        mobile_phone: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        date_of_birth: types.maybeNull(types.union(dateType, types.string)),
        health_info: types.maybeNull(types.string),
        student_guardian_relations_attributes: types.array(StudentGuardianRelation)
    })
    .views(self => ({
        get guardianRelations() {
            return self
                .student_guardian_relations_attributes
                .map((studentGuardianRelation) => {
                    const { id, student_id, guardian_id, emergency_contact, relation, authorized_to_pick_up } = studentGuardianRelation
                    const student = getParent(self).students.get(student_id)
                    const guardian = getParent(self, 2).guardians.get(guardian_id)
                    const attributes = { relation, emergency_contact, authorized_to_pick_up }
                    return {id, attributes, student, guardian}
                })
        }
    }))
    .actions(self => ({
        assignGuardian(relation) {
            self.student_guardian_relations_attributes.push(relation)
        },
        setFormInstance(student) {
            const { id, attributes, guardianRelations } = student
            applySnapshot(self, { id, ...attributes, batch_id_cache: attributes.batch_id_cache ? attributes.batch_id_cache.toString() : null })
            
            self.student_guardian_relations_attributes = guardianRelations
                .map(({ id: relationId, guardian, attributes: relationAttrs }) => ({
                    id: relationId,
                    guardian_id: guardian.id,
                    ...relationAttrs
                }))
        },
        removeGuardian: flow(function* removeGuardian(relation_id) {
            try {
                const foundIndex = self
                    .student_guardian_relations_attributes
                    .findIndex(({ id }) => id === relation_id)

                if (foundIndex >= 0) {
                    const { student_id } = self.student_guardian_relations_attributes[foundIndex]
                    yield getParent(self).unassignGuardian(relation_id, student_id)
                    self.student_guardian_relations_attributes
                        .splice(foundIndex, 1)
                } else {
                    self.student_guardian_relations_attributes
                        .splice(relation_id, 1)
                }
            } catch (err) {
                console.error("Failed to unassign guardian", err)
            }
        })
    }))

export const StudentTransaction = types
    .model({
        fee_amount: types.string,
        student_fee_ids: types.array(
            types.string
        ),
        payment_method: types.string
    })

export const Student = types.union(CreateStudent, DropStudent)
