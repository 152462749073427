import {applySnapshot, onPatch, types} from "mobx-state-tree"
import { Gender } from "../models/Gender"
import { StudentGuardianRelation } from "./StudentGuardianRelation";
import isEmpty from "lodash.isempty";

export const CreateGuardian = types
    .model("CreateGuardian", {
        first_name: types.string,
        last_name: types.string,
        middle_name: types.maybeNull(types.string),
        title: types.maybeNull(types.string),
        gender: types.maybeNull(Gender),
        photo: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        mobile_phone: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        address: types.maybeNull(types.string),
        student_guardian_relations_attributes: types.maybeNull(types.array(StudentGuardianRelation)),

        client_state: types.optional(types.model({
            isSaving: types.optional(types.boolean, false)
        }), {})
    }).actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        },
        setFormInstance(guardianModel) {
            const { attributes: { first_name, last_name, middle_name, title, gender, photo, email, mobile_phone, phone , address }, studentGuardianRelations   } = guardianModel;
            const student_guardian_relations_attributes =   studentGuardianRelations.map(({attributes : {  authorized_to_pick_up, emergency_contact, relation}, id , guardian , student}) => ({
                id,
                student_id: student.id,
                guardian_id: guardian.id,
                authorized_to_pick_up,
                emergency_contact,
                relation,
            }));
            applySnapshot(self, { first_name, last_name, middle_name, title, gender, photo, email, mobile_phone, phone , address, student_guardian_relations_attributes    });
        }
    })).views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        }
    }))

export const Guardian = types.union(CreateGuardian)
