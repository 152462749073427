import { types, applySnapshot  } from "mobx-state-tree"
// import { publishTypeOptions } from "../models/Assessment"

export const AttachedDocument = types
    .model("AttachedDocument", {
        id: types.maybeNull(types.string),
        document: types.maybeNull(types.string),
        original_file_name: types.string,
        document_url: types.maybeNull(types.string),
        _destroy: false
    })

export const publishTypeOptions = ["immediately", "due_date", "days_before_due", "specific_date"]
export const scoreDisplayAsOptions = ["points", "percentage", "letter_grade"]

export const CreateAssessment = types
    .model("CreateAssessment", {
        id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        abbreviation: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        assessment_category_id: types.maybeNull(types.string),
        possible_points: types.maybeNull(types.number),
        extra_points: types.maybeNull(types.number),
        due_date: types.maybeNull(types.string),
        include_in_final_grade: true,
        publish_type: types.optional(types.enumeration(publishTypeOptions), publishTypeOptions[0]),
        publish_days_before_due_value: types.maybeNull(types.number),
        publish_on: types.maybeNull(types.string),
        score_display_as: types.optional(types.enumeration(scoreDisplayAsOptions), scoreDisplayAsOptions[0]),
        publish_scores: true,
        subject_id: types.maybeNull(types.string),
        attached_documents_attributes: types.array(AttachedDocument)
    })
    .actions(self => ({
        setFormInstance(assessment) {
            const { 
                id, 
                name, 
                abbreviation, 
                description, 
                category, 
                possiblePoints, 
                extraPoints,
                dueDate,
                finalGrade,
                publishOn,
                publishType,
                scoreDisplay,
                publishScores,
                attributes: {publish_days_before_due_value}
            } = assessment
            
            applySnapshot(self, { 
                id, 
                name, 
                abbreviation, 
                description, 
                assessment_category_id: category, 
                possible_points: possiblePoints, 
                extra_points: extraPoints,
                due_date: dueDate,
                include_in_final_grade: finalGrade,
                publish_type: publishType,
                publish_on: publishOn,
                score_display_as: scoreDisplay,
                publish_scores: publishScores,
                publish_days_before_due_value
             })
        },
        
        attachDocument(binaryStr, original_file_name) {
            self.attached_documents_attributes.push({document: binaryStr, original_file_name})
        },
        
        setSubjectId(id) {
            self.subject_id = id
        },
        
        reset(){
            applySnapshot(self, {})
        }
    }))
